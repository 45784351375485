.contact {
    padding: 4%;
    display: grid;
    grid-template-columns: 62% 35%;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .contact-image {
        width: 100%;
        height: auto;

    }

    .talk-to-us {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;

        .input-fields {
            display: flex;
            flex-direction: column;
            gap: 20px;

            h2 {
                font-size: 36px;
                font-weight: 500;
            }

            Button {
                width: 100%;
            }

            .input-label {
                font-size: 14px;
                color: #000000;
            }

            .message-box {
                height: 75px;
            }

            input::placeholder,
            textarea::placeholder {
                font-size: 14px;
                color: #8E8E8E;

            }
        }

    }

}

@media (max-width : 750px) {
    .contact {
        grid-template-columns: 100%;

        .contact-image {
            display: none;
        }

        .talk-to-us {

            .input-fields {

                h2 {
                    font-size: 24px;
                }
            }
        }
    }
}
.btn-wrap {
  width: 100%;

  .btn {
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 7px 35px;
    background-color: $green-color;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: $green-color;
    }

    &:disabled {
      background-color: $disabled-color;
      // padding: 11px;
    }

  }
}

@media screen and (max-width: 750px) {
  .btn-wrap {
    display: flex;
    justify-content: center;

    .btn {
      font-size: 16px;
      line-height: 24px;
      padding: 10px;
    }
  }
}
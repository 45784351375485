.header-wrap {
  margin-top: 100px;
  position: relative;
  padding: 3% 4% 5%;
  background: linear-gradient(to right, #f0f9f7, #fef2f3);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  h1 {
    font-size: 64px;
    text-align: center;
    font-weight: 600;

    span {
      color: $green-color;
    }
  }

  .btn-wrap {
    width: max-content;

    .btn {
      padding: 15px 25px;
    }
  }

  .absolute-wrap {
    position: absolute;
    bottom: 15%;
    left: 10%;

    display: flex;
    align-items: center;

    .absolute-image {
      height: 50px;
      width: auto;
      object-fit: contain;
    }

    .absolute-gif {
      height: 100px;
      width: auto;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header-wrap {
    h1 {
      font-size: 54px;
    }

    .absolute-wrap {

      .absolute-gif {
        height: 85px;
      }

      .absolute-image {
        height: 40px;
      }
    }

  }
}

@media screen and (max-width: 750px) {
  .header-wrap {
    margin-top: 80px;
    gap: 20px;

    h1 {
      font-size: 36px;
    }

    .absolute-wrap {
      left: 5%;
      bottom: 5%;

      .absolute-gif {
        height: 50px;
      }

      .absolute-image {
        height: 20px;
      }
    }

    .btn-wrap {
      width: max-content;

      .btn {
        padding: 8px 15px;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .header-wrap {
    gap: 15px;

    h1 {
      font-size: 22px;
    }

    .absolute-wrap {
      left: 50%;
      bottom: -10%;
      transform: translate(-50%, -50%);
    }

    .btn-wrap{
      padding-bottom: 40px;
    }

  }
}
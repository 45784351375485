.delete-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  text-align: center;

  h1 {
    margin-bottom: 20px;
    font-size: 2rem;
  }

  p {
    max-width: 600px;
    line-height: 1.5;
  }
}

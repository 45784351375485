.navbar-wrap {
  position: relative;
  position: fixed;
  width: 100vw;
  z-index: 10;
  top: 0;
  background-color: $background-green-color;

  height: 100px;
  display: flex;
  align-items: center;

  .navbar-container {
    margin: 0 7%;
    width: 100%;
    display: grid;
    grid-template-columns: 32% auto;
    align-items: center;

    .logo-img {
      width: auto;
      height: 58px;
      object-fit: contain;
    }

    .navbar-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menus {
        display: flex;
        gap: 4rem;

        .menu {
          font-size: 16px;
          cursor: pointer;
        }
      }

      .navbar-button {
        display: flex;
        flex-direction: row;
        gap: 30px;

        .signup-button {
          .btn-wrap {
            .btn {
              background-color: transparent;
              color: black;
            }
          }
        }

        .login-button {
          display: flex;
          gap: 20px;
          justify-content: space-between;

          .bt1 {
            background-color: none;

            .btn-wrap {

              .btn {
                background-color: transparent;
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .hamburger-icon {
    cursor: pointer;
  }

  .mobile-navbar {
    background-color: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px 2px;
    position: absolute;
    top: 0;
    width: 100vw;
    height: max-content;
    transition: all 0.4s ease-in-out;

    .navbar-header {
      padding: 10% 10% 3%;
      display: flex;
      justify-content: space-between;

      .logo-icon img {
        width: 124px;
        height: 24px;
      }

    }

    .mobile-menu-container {
      padding: 10%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .menu {
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 1200px) {
  .hamburger-icon {
    display: none;
  }
}

@media (max-width: 1200px) {
  .navbar-wrap {
    .navbar-container {
      display: flex;
      justify-content: space-between;

      .navbar-menu {
        display: none;
      }
    }

    .mobile-navbar {
      .navbar-header {
        .logo-icon img {
          width: 70px;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .navbar-wrap {
    height: 80px;

    .navbar-container {
      .logo-img {
        height: 30px;
      }
    }
  }
}
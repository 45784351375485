.validation-wrap {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    max-width: 400px;

    // .input-black {}

    .otp {
        width: 100%;
    }
    .form-body{
        margin-left: 20%;
        width: 100%;
        font-size: 12px;
        margin-right: 20%;
        p a {
          font-weight: 600;
          color: $black-color;
        }
    }
    .btn {
        margin-top: 5%;
    }

    .have-an-account {
        font-size: 12px;
        display: flex;
        justify-content: center;
        gap: 10px;

        p {
            margin: 0;
            font-size: 14px;
            color: $black-color;
            font-weight: 500;
        }

        a {
            color: $success-text;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

@media (max-width: 500px) {
    .validation-wrap {
        width: 80vw;
        min-width: 0px;

        .have-an-account {
            p {
                margin: 0;
                font-size: 14px;
            }
        }
    }
}
.user-register-wrap{
  width: 27vw;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .single-row{
     display: flex;
     flex-direction: column;
     gap:12px;
  }
}
.feature-wrap {
  margin: 1% 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1 {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
  }

  .feature-container {
    padding: 2% 7%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem;
    row-gap: 6rem;

    .each-feature {
      padding: 10px 14px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 8px;

      display: flex;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;

      .img-container {
        height: 40px;
        width: 40px;
        background-color: $green-color;
        border-radius: 0px 10px 0px 10px;

        .featureImage {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      h2 {
        font-size: 22px;
        color: $title-color;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        color: $desc-color;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .feature-wrap {
    h1 {
      font-size: 36px;
    }

    .feature-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
      row-gap: 5rem;
    }
  }
}

@media (max-width: 750px) {
  .feature-wrap {
    h1 {
      font-size: 24px;
    }

    .feature-container {
      grid-template-columns: 100%;
      gap: 2.5rem;
      row-gap: 3rem;
    }
  }
}
.register-form-wrap {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    .navbar {
        display: flex;
        gap: 20px;
        width: 100%;
        height: 10%;

        .navbar-left {
            margin-left: 4%;
            padding-top: 10px;
            width: 100%;

            .img {
                height: 50px;
                width: 80px;
            }
        }

    }

    .status-container {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 10%;

        .status-info {
            display: flex;
            width: 50%;
            background-color: #fff;

            .user-info {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 20px;

                .user-container {
                    display: flex;
                    flex-direction: column;
                    border: none;
                    background-color: #fff;
                    cursor: pointer;

                    .user-img {
                        padding-left: 10px;
                        width: 40px;
                        height: 40px;
                    }

                    .user-content {
                        padding-top: 4px;
                        font-size: 10px;
                    }
                    .active {
                        color: $green-color;
                    }
                }

                .loader-img {
                    width: 20%;
                    height: 10%;
                }

            }

        }
    }

    .register-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        border-radius: 8px;


        .form-header {
            margin: 2% 0;
            width: 85%;

            h1 {
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                color: $black-color ;
                ;
            }

            p {
                margin: 1% 0 0;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                color: $black-color;
                line-height: 20px;
            }
        }

        .errorMsg {
            margin: 7px 0 0;
            font-size: 14px;
            color: $danger-text;
            font-weight: 600;
        }
    }
}

@media (max-width: 500px) {
    .register-form-wrap {
        height: 100vh;

        .form-header {
            margin: 5% 0;

            h1 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}
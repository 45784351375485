.about-wrap {
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  gap: 2rem;
  padding: 3% 7%;

  .about_image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .about-content {
    padding: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;

    h2 {
      font-size: 36px;
      color: $title-color;
      font-weight: 500;
    }

    p {

      text-align: justify;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 750px) {
  .about-wrap {
    grid-template-columns: 1fr;
    text-align: center;

    .about-content {
      padding: 5%;

      h2 {
        font-size: 22px;
        font-weight: 500;
      }

      p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
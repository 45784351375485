// bg-color
$background-green-color: #F7FEF8;
$green-color: #64c5b1;
$disabled-color: #8a8a8a;

// font-color
$title-color: #1F1F1F;
$desc-color: #4B4B4B;

$white-color: #fff;
$black-color: #1F1F1F;
$gray-color: #8e8e8e;
$gray-light-color: #ccc;
$gray-hover-color: #FAFAFA;
$gray-hover-color-1: #f5f5f5;
$yellow-color: #F19702;
$blue-color: #1A5EEC;
$label-color: #4b4b4b;
$dark-gray-color: #5c5858;
$backend-blue: #f1f5f8;
$available-color: #73AA4C;
$conflict-color: #AA4C4C;
$border-color: #CACACA;

// Success
$success-button-background-color: #30A84B;
$success-text: #30A84B;
$success-button-hover-background-color: #198431;
$success-table-header-color: #198431;
$success-table-header-background-color: #F7FEF8;

// Danger
$danger-button-background-color: #E8212E;
$danger-button-hover-background-color: #b50511;
$danger-text: #E8212E;
$danger-background: #FEF7F7;

// Default
$default-button-background-color: #ffffff;
$default-button-hover-background-color: #1F1F1F;

// Info
$info-button-background-color: #1A5EEC;
$info-button-hover-background-color: #023dba;

.contact-input {
  padding-right: 25px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  Button {
    width: 100%;
  }

  .message-box {
    height: 75px;
  }

  .input-label {
    font-size: 12px;
    color: #000000;
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 14px;
    color: #8E8E8E;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

}

@media screen and (max-width: 750px) {
  .center-button {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 650px) {
  .contact-input {
    width: 70vw;
  }
}
.success-wrap {
  width: 400px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .success-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  h2 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #30A84B;
    text-align: center;
  }
}

@media (max-width: 550px) {
  .success-wrap {
    width: 70vw;
    
    h2 {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
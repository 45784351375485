.input-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border: $border-color;
  border-radius: 3px;

  p {
    font-size: 14px;
    color: $desc-color;

    span {
      color: red;
    }
  }

  .input-box {
    border: none;
    outline: none;
    border: 1px solid #CACACA;
    border-radius: 3px;
    padding: 11px 0px 11px 15px;
    font-size: 14px;
    
    &::placeholder {
      color:$border-color;
      font-size: 14px;
    }
  }
}

.phone-number-wrap {
  position: relative;

  p {
    position: absolute;
    left: 50px;
    top: 3px;
    font-size: 18px;
    color: $desc-color;
    pointer-events: none;

    span {
      color: red;
    }
  }

  .PhoneInput {
    border-bottom: 1px solid #000;
    padding: 0 0 10px 0;

    input {
      margin-left: 5px;
      outline: none !important;
      border: none !important;
      font-size: 18px !important;
    }
  }
}

@media screen and (max-width: 750px) {
  .input-wrap {
    .input-box {
      padding: 8px;
    }

    p {
      font-size: 14px;
    }
  }

  .phone-number-wrap {

    p {
      font-size: 14px;
    }

    // .PhoneInput {
    //   padding: 0 0 5px 0;
    // }
  }

  input {
    font-size: 14px !important;
  }

}
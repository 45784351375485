.company-form-wrap {
  width: 70vh;
  min-width: 750px;

  .form-container {
    padding: 0 1%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    .single-row {
      display: grid;
      grid-template-columns: 47% 47%;
      justify-content: space-between;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4% 1% 0 0;

  
  }
}
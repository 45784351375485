.modal-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    // width: 60%;

    .modal-header{
        // padding: 0px 0px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        

        .modal-header-title {
            font-weight: 600;
            font-size: 18px;
            color: #1F1F1F;
        }

        .modal-close-button {
            width: 19px;
            height: 19px;
            cursor: pointer;
            
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .modal-body {

        gap: 20px;

        .table {
            border-radius: 15px;
        }
    }

    .modal-header-title {
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        .modal-title {
            color: #1F1F1F;
            font-size: 19px;
            font-weight: 600;
        }
    
        .modal-body-content {
            color: #1F1F1F;
            font-size: 14px;
            font-weight: 400;
        }

    }

    .modal-delete-buttons {
        display: flex;
        justify-content: space-around;
        width: 100%;

        .btn{
            width: 25%;
        }
    }
}
.policy-wrap{
    padding: 0.5% 3%;

    h1{
        font-weight: 700;
        margin: 1% 0;
        font-size: 22px;
    }

    h2{
        font-weight: 600;
        margin: 1% 0;
        font-size: 18px;
    }

    p{
        font-size: 16px;
    }

    ul{
        margin: 1% 3%;
    }
}
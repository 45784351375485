.footer-wrap {
  padding: 0 5% 2.5%;
  background-color: #263238;
  color: #ffffff;

  .footer-container {
    padding: 3% 0 3% 0;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap:20%;
    border-bottom: 0.1px solid #8e8e8e;

    .title {
      font-size: 20px;
      font-weight: 500;
    }

    .menu {
      font-size: 14px;
      font-weight: 300;
    }


    .footer-logo {
      width: auto;
      height: 75px;
      object-fit: contain;
    }

    .footer-menu {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .menus {
        display: flex;
        gap: 2rem;

        .menu {
          padding-right: 2rem;
          border-right: 1px solid #fff;
          cursor: pointer;
        }

        .menu-list {
          cursor: pointer;
        }
      }
    }

    .footer-details {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .detail {
        display: flex;
        gap: 1rem;

        .detailImg {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        .menu {
          width: 75%;
          line-height: 22px;
        }
      }
    }

    .footer-follow {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      .footer-icons {
        display: flex;
        gap: 1.5rem;

        .icon-image {
          width: auto;
          height: 30px;
          object-fit: contain;
        }

        .logo-icon {
          width: auto;
          height: 30px;
          object-fit: contain;
        }
      }
    }
  }

  .privacy {
    font-size: 14px;
    color: #F5F7FA;
    text-align: center;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }


  p {
    width: auto;
    font-size: 14px;
    color: #F5F7FA;
    text-align: center;
    font-weight: 300;
  }

  .privacy {
    padding-top: 2%;
    display: flex;
    flex-direction: row;


    .privacy-policy {
      display: flex;
      width: 100%;
      justify-content: flex-start;

    }

    .copyright {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 1200px) {
  .copyright-mobile {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .footer-wrap {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .copyright {
      display: none !important;
    }
  }
}

@media (max-width: 750px) {
  .footer-wrap {
    .footer-container {
      .footer-logo {
        width: auto;
        height: 55px;
        object-fit: contain;
      }

      .footer-menu {
        .menus {
          flex-direction: column;
          gap: 1rem;

          .menu {
            padding-right: 0rem;
            border-right: none;
          }
        }
      }
    }

    p {
      font-size: 12px;
    }
  }
}